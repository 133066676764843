<template>
  <div id="home-header">
    <transition name="fade">
      <home-background v-if="show" />
    </transition>
    <transition name="fade">
      <div v-if="show" class="title">
        <b-row class="align-items-center">
          <b-col cols="9" sm="10">
            <div id="home-title-container" class="d-inline-block">
              <vc-app-title size="lg" />
            </div>
          </b-col>
          <b-col cols="3" sm="2" class="text-center">
            <vc-app-profile-btn :headshot-src="headshotSrc" size="lg" />
          </b-col>
        </b-row>
      </div>
    </transition>
  </div>
</template>

<script>
import HomeBackground from "@/components/Home/HomeBackground";

export default {
  name: "HomeHeader",
  components: {
    HomeBackground
  },
  data() {
    return {
      show: false
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
    fullName() {
      return `${this.currentUser.firstName} ${this.currentUser.lastName}`;
    },
    suffix() {
      return this.currentUser.suffix;
    },
    headshotSrc() {
      return this.currentUser.headshot;
    }
  },
  mounted() {
    setTimeout(() => (this.show = true), 100);
  }
};
</script>

<style scoped lang="scss">
#home-header {
  padding-top: 15px;
  height: 220px;

  @media (min-width: 576px) {
    height: 380px;
  }
}
</style>